<template>
	<div>
		<ddcard class="box-card" v-loading="isLoading">
			<div slot="title">
				<svg-icon icon-class="clipboard" class="dashboard-icon-card" />
				<span class="dd-title">{{ $t('dashboard.left.ann-header') }}</span>
			</div>
			<el-tabs class="announcements-tabs">
				<el-tab-pane value="1">
					<span slot="label"><i class="el-icon-warning"></i> {{$t('dashboard.left.ann-news')}}</span>
					<template v-if="announcements.length > 0">
						<el-table size="mini" :data="announcements" @row-click="showAnnouncement"
							:row-class-name="announcementsRow" :show-header="false" style="width: 100%">
							<el-table-column prop="title" class-name="announcement-col">
								<template slot-scope="scope">
									<svg-icon v-if="scope.row.seen === 0" icon-class="primitive-dot"
										class="ann-not-seen" />
									<span class="text" v-html="scope.row.title"
										v-bind:class="{ bold: scope.row.seen == 0 }"></span>
								</template>
							</el-table-column>
						</el-table>

						<el-dialog :visible.sync="announcementsDialog.showModal" custom-class="announcement-dialog"
							width="30%">
							<span slot="title" v-html="announcementsDialog.title"></span>
							<span v-html="announcementsDialog.message"></span>
						</el-dialog>
					</template>
					<template v-else>
						<div class="no-data">{{$t('dashboard.left.ann-no-data')}}</div>
					</template>
				</el-tab-pane>
				<el-tab-pane value="2" label="Comenzi">
					<span slot="label"><i class="el-icon-goods"></i>
						<template v-if="haveOrders">
							<el-badge :value.sync="totalOrders" class="item centralizator">
								{{$t('dashboard.left.ann-orders')}} </el-badge>
						</template>
						<template v-else>
							{{$t('dashboard.left.ann-orders')}}
						</template>
					</span>
					<div class="mini-separator"></div>
					<div class="body">
						<div class="title">Comenzi nealocate</div>
						<el-table size="mini" :data="orders.unallocated" style="width: 100%">
							<el-table-column prop="orders_id" label="Numar comanda">
								<template slot-scope="scope">
									<router-link :to="{path: `/vanzari/comanda/${scope.row.orders_id}`}">
										{{scope.row.orders_id}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="date_purchased" label="Data">
							</el-table-column>
						</el-table>
					</div>
					<div class="body">
						<div class="title">Comenzi alocate</div>
						<el-table size="mini" :data="orders.allocated" style="width: 100%">
							<el-table-column prop="orders_id" label="Numar comanda">
								<template slot-scope="scope">
									<router-link :to="{path: `/vanzari/comanda/${scope.row.orders_id}`}">
										{{scope.row.orders_id}}</router-link>
								</template>
							</el-table-column>
							<el-table-column prop="date_purchased" label="Data">
							</el-table-column>
						</el-table>
					</div>
				</el-tab-pane>
				<el-tab-pane value="3" label="Produse">
					<span slot="label"><i class="el-icon-menu"></i>
						<template v-if="products.total > 0">
							<el-badge :value.sync="products.total" class="item centralizator">
								{{$t('dashboard.left.ann-products')}} </el-badge>
						</template>
						<template v-else>
							{{$t('dashboard.left.ann-products')}}
						</template>
					</span>
					<template v-if="products.total > 0 && products.waiting != null && products.rejected != null">
						<div class="mini-separator"></div>
						<div class="body">
							<div class="title">Produse in asteptare (Primele 10)</div>
							<el-table size="mini" :data="products.waiting.data" style="width: 100%">
								<el-table-column prop="orders_id" label="Nume produs">
									<template slot-scope="scope">
										<router-link
											:to="{path: `/produse/produs/${encodeProductsModel(scope.row.products_model)}`}">
											{{scope.row.products_name}}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="products_model" label="Cod Produs">
								</el-table-column>
							</el-table>
							<template v-if="products.waiting.data && products.waiting.data.length > 0">
								<div class="separator"></div>
								<router-link :to="{path: `/produse/in-asteptare`}">
									<el-button size="mini" plain class="fullWidth b-r-ia" type="primary">
										Vezi lista completa
									</el-button>
								</router-link>
							</template>
						</div>
						<div class="body">
							<div class="title">Produse refuzate (Primele 10)</div>
							<el-table size="mini" :data="products.rejected.data" style="width: 100%">
								<el-table-column prop="orders_id" label="Nume produs">
									<template slot-scope="scope">
										<router-link
											:to="{path: `/produse/produs/${encodeProductsModel(scope.row.products_model)}`}">
											{{scope.row.products_name}}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="products_model" label="Cod Produs">
								</el-table-column>
								<el-table-column prop="motiv" label="Motiv">
								</el-table-column>
							</el-table>
							<template v-if="products.rejected.data && products.rejected.data.length > 0">
								<div class="separator"></div>
								<router-link :to="{path: `/produse/respinse`}">
									<el-button size="mini" plain class="fullWidth b-r-pr" type="primary">
										Vezi lista completa
									</el-button>
								</router-link>
							</template>
						</div>
					</template>
					<template v-else>
						<div class="no-data">{{$t('dashboard.left.ann-no-data')}}</div>
					</template>
				</el-tab-pane>
				<el-tab-pane value="4" label="Retur">
					<span slot="label"><i class="el-icon-menu"></i>
						<template v-if="returns.total > 0">
							<el-badge :value.sync="returns.total" class="item centralizator">
								Retur </el-badge>
						</template>
						<template v-else>
							Retur
						</template>
					</span>
					<template v-if="returns.total > 0 && returns.waiting != null">
						<div class="mini-separator"></div>
						<div class="body">
							<div class="title">Cereri retur in asteptare (Cele mai noi 10 cereri)</div>
							<el-table size="mini" :data="returns.waiting" style="width: 100%">
								<el-table-column prop="products_name" label="Nume produs">
									<template slot-scope="scope">
										<router-link
											:to="{path: `/produse/produs/${encodeProductsModel(scope.row.products_model)}`}">
											{{scope.row.products_name}}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="products_model" label="Cod Produs">
								</el-table-column>
								<el-table-column prop="products_name" label="Actiuni" width="120px">
									<template slot-scope="scope">
										<router-link :to="{path: `/comunicare/retur_produs/${scope.row.id_unic}`}">
											<el-button size="mini" plain class="fullWidth b-r-rp" type="primary">
												{{$t('general.details')}}
											</el-button>
										</router-link>
									</template>
								</el-table-column>
							</el-table>
							<template v-if="returns.waiting && returns.waiting.length > 0">
								<div class="separator"></div>
								<router-link :to="{path: `/comunicare/retur`}">
									<el-button size="mini" plain class="fullWidth b-r-cr" type="primary">
										Vezi lista completa
									</el-button>
								</router-link>
							</template>
						</div>
					</template>
					<template v-else>
						<div class="no-data">{{$t('dashboard.left.ann-no-data')}}</div>
					</template>
				</el-tab-pane>
				<el-tab-pane value="5" label="Service">
					<span slot="label"><i class="el-icon-menu"></i>
						<template v-if="service.total > 0">
							<el-badge :value.sync="service.total" class="item centralizator">
								Service </el-badge>
						</template>
						<template v-else>
							Service
						</template>
					</span>
					<template v-if="service.total > 0 && service.waiting != null">
						<div class="mini-separator"></div>
						<div class="body">
							<div class="title">Cereri service in asteptare (Cele mai noi 10 cereri)</div>
							<el-table size="mini" :data="service.waiting" style="width: 100%">
								<el-table-column prop="orders_id" label="Nume produs">
									<template slot-scope="scope">
										<router-link
											:to="{path: `/produse/produs/${encodeProductsModel(scope.row.products_model)}`}">
											{{scope.row.products_name}}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="products_model" label="Cod Produs">
								</el-table-column>
								<el-table-column prop="products_name" label="Actiuni" width="120px">
									<template slot-scope="scope">
										<router-link :to="{path: `/comunicare/service_produs/${scope.row.id_unic}`}">
											<el-button size="mini" plain class="fullWidth b-r-sp" type="primary">
												{{$t('general.details')}}
											</el-button>
										</router-link>
									</template>
								</el-table-column>
							</el-table>
							<template v-if="service.waiting && service.waiting.length > 0">
								<div class="separator"></div>
								<router-link :to="{path: `/comunicare/service`}">
									<el-button size="mini" plain class="fullWidth b-r-cs" type="primary">
										Vezi lista completa
									</el-button>
								</router-link>
							</template>
						</div>
					</template>
					<template v-else>
						<div class="no-data">{{$t('dashboard.left.ann-no-data')}}</div>
					</template>
				</el-tab-pane>
				<el-tab-pane value="4" label="Intrebari clienti"
					v-if="!hasModuleRestriction('communication.questions')">
					<span slot="label"><i class="el-icon-menu"></i>
						<template v-if="questions.total > 0">
							<el-badge :value.sync="questions.total" class="item centralizator">
								Intrebari </el-badge>
						</template>
						<template v-else>
							Intrebari
						</template>
					</span>
					<template v-if="questions.total > 0 && questions.noanswer != null">
						<div class="mini-separator"></div>
						<div class="body">
							<div class="title">Intrebari de la clienti fara raspuns (Ultimele 10 intrebari)</div>
							<el-table size="mini" :data="questions.noanswer" style="width: 100%">
								<el-table-column prop="orders_id" label="Nume produs">
									<template slot-scope="scope">
										<router-link
											:to="{path: `/produse/produs/${encodeProductsModel(scope.row.products_model)}`}">
											{{scope.row.products_name}}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="products_model" label="Cod Produs">
								</el-table-column>
								<el-table-column prop="products_name" label="Actiuni" width="120px">
									<template slot-scope="scope">
										<router-link :to="{path: `/comunicare/intrebare_produs/${scope.row.id}`}">
											<el-button size="mini" plain class="fullWidth b-r-ip" type="primary">
												{{$t('general.details')}}
											</el-button>
										</router-link>
									</template>
								</el-table-column>
							</el-table>
							<template v-if="questions.noanswer && questions.noanswer.length > 0">
								<div class="separator"></div>
								<router-link :to="{path: `/comunicare/intrebari`}">
									<el-button size="mini" plain class="fullWidth b-r-ci" type="primary">
										Vezi lista completa
									</el-button>
								</router-link>
							</template>
						</div>
					</template>
					<template v-else>
						<div class="no-data">{{$t('dashboard.left.ann-no-data')}}</div>
					</template>
				</el-tab-pane>
				<el-tab-pane value="4" label="Cereri anulare comenzi">
					<span slot="label"><i class="el-icon-menu"></i>
						<template v-if="cor.total > 0">
							<el-badge :value.sync="cor.total" class="item centralizator">
								Cereri anulare comenzi </el-badge>
						</template>
						<template v-else>
							Cereri anulare comenzi
						</template>
					</span>
					<template v-if="cor.total > 0 && cor.pending != null">
						<div class="mini-separator"></div>
						<div class="body">
							<div class="title">Cereri anulare comenzi nerezolvate</div>
							<el-table size="mini" :data="cor.pending" style="width: 100%">
								<el-table-column prop="id" label="ID referinta"></el-table-column>
								<el-table-column prop="orders_id" label="ID comanda">
									<template slot-scope="scope">
										<router-link :to="{path: `/vanzari/comanda/${scope.row.orders_id}`}">
											{{scope.row.orders_id}}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="customers_name" label="Nume client">
								</el-table-column>
							</el-table>
						</div>
					</template>
					<template v-else>
						<div class="no-data">{{$t('dashboard.left.ann-no-data')}}</div>
					</template>
				</el-tab-pane>
				<!-- <el-tab-pane value="4" label="Cont">
					<span slot="label"><i class="el-icon-tickets"></i> Cont</span>
				</el-tab-pane>
				<el-tab-pane value="5" label="Plati">
					<span slot="label"><i class="el-icon-edit"></i> Plati</span>
				</el-tab-pane> -->
			</el-tabs>
		</ddcard>
	</div>
</template>

<script>
	const ddcard = () => import('@/components/DDCard');
	import {
		mapState,
		mapGetters
	} from 'vuex';
	export default {
		components: {
			ddcard
		},
		filters: {
			statusFilter(status) {
				const statusMap = {
					success: 'success',
					pending: 'danger'
				};
				return statusMap[status];
			},
			orderNoFilter(str) {
				return str.substring(0, 30);
			}
		},
		data() {
			return {
				list: null,
				announcementsDialog: {
					showModal: false,
					title: '',
					message: ''
				}
			};
		},
		methods: {
			async getAnnouncements() {
				try {
					await this.$store.dispatch('dashboard/GetAnnouncements');
				} catch (e) {
					console.log(e);
				}
			},
			encodeProductsModel(model) {
				return window.btoa(model);
			},
			showAnnouncement(row) {
				if (row.seen == 0) {
					this.$store.dispatch('dashboard/SetAnnouncementRead', {
						announcement_id: row.id
					}).then((res) => {
						if (res == null) return false;
						if (res.message.seen == true) {
							row.seen = 1;
						}

						this.announcementsDialog.title = row.title;
						this.announcementsDialog.message = row.message;
						this.announcementsDialog.showModal = true;
					});
				} else {
					this.announcementsDialog.title = row.title;
					this.announcementsDialog.message = row.message;
					this.announcementsDialog.showModal = true;
				}
			},
			announcementsRow({
				row,
				rowIndex
			}) {
				if (row.seen == 0) return 'unseen';
				return '';
			},
			hasModuleRestriction(theModule) {
				const module = theModule.split('.');
				const baseModule = module[0];
				return this.restrictions.some((restriction) => {
					return restriction.impact.some((impact) => {
						return impact.name === theModule || (!(/\./.test(impact.name)) &&
							baseModule === impact.name);
					});
				});
			}
		},
		computed: {
			...mapState({
				announcements: (state) => state.dashboard.centralizator.announcements,
				orders: (state) => state.dashboard.centralizator.orders,
				products: (state) => state.dashboard.centralizator.products,
				returns: (state) => state.dashboard.centralizator.returns,
				service: (state) => state.dashboard.centralizator.service,
				questions: (state) => state.dashboard.centralizator.questions,
				cor: (state) => state.dashboard.centralizator.cor,
				isLoading: (state) => state.dashboard.centralizator.loading,
				loaded: (state) => state.dashboard.centralizator.loaded,
				restrictions: (state) => state.user.restrictions,
			}),
			...mapGetters({
				totalOrders: 'dashboard/totalOrders',
				totalProducts: 'dashboard/totalProducts'
			}),
			haveOrders() {
				return this.orders.total > 0;
			}
		},
		created() {

		},
		mounted() {
			if (!this.loaded) {
				this.getAnnouncements();
			}
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.title {
		font-size: 14px;
	}

	.ann-not-seen {
		font-size: 17px;
		vertical-align: middle;
		bottom: 1px;
		position: relative;
	}

	.no-data {
		font-size: 14px;
		color: #4e4e4e;
		padding-top: 10px;
		text-align: center;
	}

	.el-badge.centralizator ::v-deep {
		.el-badge__content {
			font-size: 10px;
			padding: 0px 5px;

		}

		.el-badge__content.is-fixed {
			top: 9px !important;
			right: 0px !important;
		}
	}

	.bold {
		font-weight: 600;
	}

	::v-deep .announcement-col {
		cursor: pointer;
		font-size: 13px;
	}

	::v-deep .announcement-dialog .el-dialog__header {
		margin-right: 15px;
	}
</style>